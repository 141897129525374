//@import "../../../public/sass/function";
@import "../function";
@import "../../../public/sass/base/base";
@import "../../../public/sass/layout/associatedprogramme";
@import "../../../public/sass/layout/evaluationsystem";
@import "../../../public/sass/layout/logos-qrcode";
@import "../../../public/sass/layout/modelconcept";
@import "../../../public/sass/layout/partners";
//@import "layout/navbar";
@import "../../components/homeCompany/footer/Footer";
@import "../../components/homeCompany/header/Header";
@import "../../../public/sass/layout/header";
@import "../../../public/sass/layout/repertorydescription";
@import "../../../public/sass/module/button";
@import "../../../public/sass/module/card";
@import "../../../public/sass/module/category";
$screenSize: 1920;
$mobileScreenSize: 411;

@mixin media-max($max) {
  @media screen and (max-width: $max) {
    & {
      @content;
    }
  }
}
#scrolldeny {
  position: fixed;
}
body {
  font-family: "Droid Sans", sans-serif;
  //background-color: #f8f8f8;
  margin: 0;
  width: 100%;
}

.hompageBackground {
  background-image: url("../../../public/images/header_entreprise.jpg");
  width:100%;
  height: 100vh;
  background-position:center ;
  background-size: cover ;
  background-repeat: no-repeat;
}

.list-one {
  margin-left: 20%;
}
.list-two {
  margin-left: 20%;
  margin-right: 20%;
}
.list-one,
.list-two {
  display: inline-block;
  vertical-align: middle;
}
.associatedprogramme {
  display: inline-block;
  vertical-align: middle;
}
.partners {
  margin-right: auto;
}
.container-evaluationsystem .content-evaluationsystem {
  padding-left: 13.3854166667%;
  font-size: pxToVW(20px, 1920px);
}

.container-evaluationsystem {
  padding-top: 4.5833333333%;
  padding-bottom: 4.8958333333%;
  font-size: 1.25vw;
}

.showcase-sous-title h1 {
  font-size: 6vw;
  font-weight: bold;
  color: #618226;
  /*margin-bottom: 1.3%;*/
  margin-bottom: 2.5vw;
  font-family: "Droid Sans", sans-serif;
}

.showcase-title {
  opacity: 1;

  margin-left: pxToPercent(799, $screenSize);
  margin-right: pxToPercent(799, $screenSize);
  margin-left: pxToPercent(100, $mobileScreenSize);
  margin-right: pxToPercent(100, $mobileScreenSize);
}

.showcase-title-firstPage {
  opacity: 1;
  margin-left: 40%;
  margin-right: 40%;
}

.showcase-title h2 {
  font-size: 2.083vw;
  color: #618226;
  font-weight: bold;
  font-family: "Droid Sans", sans-serif;
}

.showcase-title-firstPage h2 {
  font-size: 2.083vw;
  color: #618226;
  font-weight: bold;
  font-family: "Droid Sans", sans-serif;
}

.paragraphe-showcase {
  /* font-size: clamp(35px,5vw,70px);*/
  color: #000000;
  text-align: center;
  margin-bottom: 14.063vw;
  margin-right: 19.1%;
  margin-left: 19.1%;
  font-size: 1.042vw;
  font-weight: 600;
  font-family: "Droid Sans", sans-serif;
}

.showcase__btn {
  padding-bottom: 2%;
}

.fa-building {
  padding-right: 1.042vw;
}

.fa-user {
  padding-right: 1.042vw;
}

.fa-city {
  padding-right: 1.042vw;
}

.showcase__btn-entreprise {
  border: white solid 3px;
  background-color: #618226;
  margin-right: 1.563vw;
  border-radius: 9px;
  color: white;
  /*font-size: 24px;*/
  font-size: 1.3vw;
  width: 12.083vw;
  /*height: 8.5vh;*/
  height: 3.646vw;
}

.showcase__btn-entreprise1 {
  border: white solid 3px;
  background-color: black;
  margin-right: 1.563vw;
  border-radius: 9px;
  color: white;
  /*font-size: 24px;*/
  font-size: 1.3vw;
  width: 12.083vw;
  /*height: 8.5vh;*/
  height: 3.646vw;
}

.showcase__btn-individu {
  border: white solid 3px;
  background-color: white;
  margin-right: 2.917vw;
  border-radius: 9px;
  color: black;
  /*font-size: 24px;*/
  font-size: 1.3vw;
  width: 12.083vw;
  /*height: 8.5vh;*/
  height: 3.646vw;
}

.showcase__btn-ville {
  border: white solid 3px;
  background-color: white;
  border-radius: 9px;
  color: black;
  /*font-size: 24px;*/
  font-size: 1.3vw;
  width: 12.083vw;
  /*height: 8.5vh;*/
  height: 3.646vw;
}

.showcase__btn-ville1 {
  border: white solid 3px;
  background-color: black;
  border-radius: 9px;
  color: black;
  /*font-size: 24px;*/
  font-size: 1.3vw;
  width: 12.083vw;
  /*height: 8.5vh;*/
  height: 3.646vw;
}

.list-one {
  margin-left: 20%;
}
.list-two {
  margin-left: 20%;
  margin-right: 20%;
}
.list-one,
.list-two {
  display: inline-block;
  vertical-align: middle;
}

.partners {
  margin-right: auto;
}
.container-evaluationsystem .content-evaluationsystem {
  padding-left: 13.3854166667%;
  font-size: pxToVW(20px, 1920px);
}

.container-evaluationsystem {
  padding-top: 4.5833333333%;
  padding-bottom: 4.8958333333%;
  font-size: 1.25vw;
}

/////////////////////////////////////////////////////
////////////////////////////////////////////////////

///// LOGO ///////////////////
.div-banner-rv {
  width: 100%;
  display: flex;
  height: 97vh;
  margin-bottom: 5%;
}
.container-banner-rv {
  width: 87%;
  display: flex;
  margin: 1.3% 3.125% 0% 9.9%;
  justify-content: center;
}
.div-logo-rv {
  width: 10.6%;
  margin-right: 5.15%;
}
.div-logo-rv img {
  width: 100%;
}
///// TEXT BIENVENUE ///////////////////
.div-title-rv {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 9.9% 9.9% 25% 0%;
}
.container-title-rv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title-rv1 {
  color: #618226;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 2.08vw;
  /*@include media-max(480px){
    font-size: 4.08vw;
  }*/
}
.title-rv2 {
  color: #618226;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 7.3vw;
}
.title-rv-text {
  color: #535353;
  display: flex;
  justify-content: center;
  font-size: 1.14vw;
}
///// LOGIN RV  ///////////////////
.div-login-rv {
  display: flex;
  color: #ffffff;
  font-size: 1.04vw;
  width: 8%;
}
.container-login-rv {
  width: 50%;
  cursor: pointer;
}
.choix-langue-rv {
  display: flex;
  width: 36.3%;
}
.div-pipe-rv {
  margin: 0% 10%;
}
///////////////////////////////////////////////////
//// SELECTION JE SUIS /////////////////////////

.div-jesuis {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-jesuis {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.51%;
  margin-bottom: 1.875%;
}
.title-jesuis {
  color: #618226;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.51vw;
  font-weight: bold;
  margin-bottom: 2.86%;
}


.data-jesuis {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21.33%;
  color: white;
  text-align: center;
  background-color: #618226;
  border-radius: 2.6vw;
  font-size: 1.04vw;
  padding: 2.5% 0%;
  cursor: pointer;
}
.data-jesuis:not(:last-of-type) {
  margin-right: 18%;
}
////////////////////////////
// LOGIN//////////////
.div-login {
  position: absolute;
  width: 100%;
  background-color: white;
  margin-top: 5.833333vw;
}
.container-login {
  width: 100%;
  margin: 1.98% 7.02% 4.11% 9.6875%;
  display: flex;
}
.logo-login {
  width: 3.22%;
  margin-right: 1.4%;
}
.logo-login img {
  width: 100%;
}
.div-form-login {
  width: 27.8125%;
  display: flex;
  flex-direction: column;
  margin-right: 28.28%;
}
.div-title-form-login {
  width: 100%;
  color: #618226;
  font-size: 1.04vw;
  font-weight: bold;
}
.container-title-form-login {
  width: 100%;
  display: flex;
}
.container-title-form-login span {
  margin-right: 5%;
}
.div-datas-login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6.36%;
}
.container-datas-login {
  width: 100%;
  display: flex;
}
.data-login-rv {
  width: 41.3%;
  display: flex;
  flex-direction: column;
  font-size: 1.04vw;
}
.data-login-rv:not(:last-of-type) {
  margin-right: 17.2%;
}
.label-login-rv {
  margin-bottom: 2%;
}
.input-login-rv {
  width: 100%;
  border: solid 0.05vw #618226;
  border-radius: 0.52vw;
  outline: none;
  padding: 1% 2%;
}
.div-valider-login {
  width: 6.5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 6.9%;
  margin-right: 4.27%;
}
.btn-valider-login {
  width: 100%;
  color: white;
  background-color: #618226;
  border-radius: 2.6vw;
  font-size: 1.04vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6.4% 0%;
  cursor: pointer;
}
.div-image-lac {
  width: 11.77%;
  margin-top: 0.88%;
}
.div-image-lac img {
  width: 100%;
}

#error {
  font-size: 1.04vw;
  margin-top: 2%;
}

////////////////////////////////////////////////
////////////////////////////

.div-rv-entreprise {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.rv-entreprise1 {
  width: 100%;
  color: #618226;
  font-size: 2.08vw;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.rv-entreprise2 {
  width: 100%;
  color: #618226;
  font-size: 4.6075vw;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.rv-entreprise-text {
  width: 100%;
  color: #ffffff;
  font-size: 1.15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1.5%;
}





// a enlever plus tard
.menu-inactif {
  visibility: hidden;
  opacity: 0;
  display: none;
}

@media screen and (max-width: 1024px) and (max-height: 600px) {
  body {
    height: 100vh;
  }

  .showcase.welcome {
    height: 75vh !important;
  }

  .title-rv1 {
    font-size: 2.4vw;
  }
  .title-rv2 {
    font-size: 5.1vw;
  }
  .title-rv-text {
    font-size: 1.5vw;
  }

  .title-jesuis {
    font-size: 2vw;
  }

  .data-jesuis {
    width: 100%;
    border-radius: 3.6vw;
    font-size: 1.5vw;
    padding: 2% 1%;
  }
}

@media screen and (max-width: 1024px) and (min-height: 700px) {
  body {
    height: 100vh;
  }

  .showcase.welcome {
    height: 89vh !important;
  }

  .div-rv-entreprise {
    //margin: 9.9% 9.9% 21% 0%;

  }

  .title-rv1 {
    font-size: 3.08vw;
  }
  .title-rv2 {
    font-size: 7.6vw;
  }
  .title-rv-text {
    font-size: 2.14vw;
  }

  .title-jesuis {
    font-size: 2.51vw;
  }

  .data-jesuis {
    width: 100%;
    border-radius: 3.6vw;
    font-size: 2.04vw;
    padding: 2.5% 1%;
  }
}

@media screen and (max-width: 480px) {
  body {
    height: 100vh;
  }

  .showcase.welcome {
    height: 89vh !important;
  }

  .div-rv-entreprise {
  }

  .title-rv1 {
    font-size: 4.08vw;
  }
  .title-rv2 {
    font-size: 8vw;
  }
  .title-rv-text {
    font-size: 3.14vw;
  }

  .title-jesuis {
    font-size: 3.51vw;
  }


  .data-jesuis {
    width: 100%;
    border-radius: 3.6vw;
    font-size: 3.04vw;
    padding: 2.5% 1%;
  }

  .data-jesuis:not(:last-of-type) {
    margin-right: 4%;
  }
}
.partners {
  height: 100vh;
  background-image: url("/public/images/carousel_background.png");
}
.test{
  color: red;
}
.partners .partners-title {
  padding-top: 10vh;
  padding-left: unset;
  margin-top: 15vh;
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
  font-weight: bold;
  font-size: 2.2916666667vw;
}
.partners .partners-images {
  width: 100%;
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.partners .partners-images img {
  width: 15vw;
}

.partners .partners-description {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  margin-top: 10vh;
}

.partners .partners-description p {
  width: 18.75vw;
  color: white;
}

.partners .partners-description .name {
  color: #e0ddc9;
  font-size: 1.5em;
  font-weight: bold;
}

.partners .partners-description .function {
  color: #e0ddc9;
  border-bottom: 1px solid #e0ddc9;
  font-size: 1.1em;
  font-weight: bold;
}

.partners .partners-links {
  display: flex;
  justify-content: space-between;
  margin-top: 6vh;
}

.partners .partners-links p {
  margin-bottom: 0;
  color: white;
  border-bottom: 1px solid white;
}

.partners_arrow {
  display: flex;
}

.partners_arrow img {
  margin-left: 0.5vw;
}

.logo_gaea {
  padding-top: 6vh;
}

.entraide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10vh 0vh;
  padding-inline: 10%;
}

.entraide img {
  width: 20vw;
}
*{
  margin-top: 0px;
}
