$width_screen: 1920px;

.modelconcept-container{
  max-width: 100%;
  

  .modelconcept-title {
      color: #618226;
      font-style: Droid Sans, Bold;
      font-weight: bold;
      text-align: center;
      padding-top: pxToPourcent(139,$width_screen);
      padding-bottom: pxToPourcent(82,$width_screen);
      font-size: pxToVw(44px,$width_screen);
      font-weight: bold;
  }

  .modelconcept-content {
    padding-left: pxToPourcent(190px,$width_screen);
    font-size: pxToVw(20px,$width_screen);
    padding-bottom: pxToPourcent(58px,$width_screen);
  }

  .modelconcept-list {
    width: 100%;
    font-size: pxToVw(20px,$width_screen);
    display: flex;

    .list-container {
      display: flex;
      
    }

    .logo-1 {
      width: pxToVw(30px,$width_screen);
      padding-right: pxToPourcent(44px, $width_screen);
      height: pxToPourcent(34.29px,$width_screen);
    }
    .logo-2 {
      width: pxToVw(30px,$width_screen);
      padding-right: pxToPourcent(44px, $width_screen);
      height: pxToPourcent(30px,$width_screen);
    }
    .logo-3 {
      width: pxToVw(30px,$width_screen);
      padding-right: pxToPourcent(44px, $width_screen);
      height: pxToPourcent(26.67px,$width_screen);
    }
    .logo-4 {
      width: pxToVw(30px,$width_screen);
      padding-right: pxToPourcent(44px, $width_screen);
      height: pxToPourcent(30px,$width_screen);
    }
    .logo-5 {
      width: pxToVw(27px,$width_screen);
      padding-right: pxToPourcent(44px, $width_screen);
      height: pxToPourcent(32.06px,$width_screen);
    }

    .list-two {

      .logo-6 {
        width: pxToVw(30px,$width_screen);
        padding-right: pxToPourcent(44px, $width_screen);
        height: pxToPourcent(34.29px,$width_screen);
      }
      .logo-7 {
        width: pxToVw(30px,$width_screen);
        padding-right: pxToPourcent(44px, $width_screen);
        height: pxToPourcent(30px,$width_screen);
      }
      .logo-8 {
        width: pxToVw(30px,$width_screen);
        padding-right: pxToPourcent(44px, $width_screen);
        height: pxToPourcent(33.71px,$width_screen);
      }
      .logo-9 {
        width: pxToVw(23px,$width_screen);
        padding-right: pxToPourcent(44px, $width_screen);
        height: pxToPourcent(35.35px,$width_screen);
      }
    }
  
  }
}

@media screen and (max-width: 1024px) and (max-height: 600px) {

  .modelconcept-container{


    .modelconcept-content {
      font-size: 1.5vw;
    }
    .modelconcept-list{
      font-size: 1.5vw;

      .logo-1,.logo-2,.logo-3,.logo-4,.logo-5{
        width: 3vw;
        padding-right: 7%;
      }

      .list-two{
        .logo-6,.logo-7,.logo-8,.logo-9{
          width: 3vw;
          padding-right: 7%;
        }
      }
    }
  }

}



@media screen and (max-width: 1024px) and (min-height: 620px) {

  .modelconcept-container{
    max-width: 93%;
    .modelconcept-title{
      font-size: 4vw;
    }

    .modelconcept-content {
      font-size: 3vw;
    }
    .modelconcept-list{
      font-size: 3vw;

      .logo-1,.logo-2,.logo-3,.logo-4,.logo-5{
        width: 6vw;
        padding-right: 7%;
      }

      .list-two{
        .logo-6,.logo-7,.logo-8,.logo-9{
          width: 6vw;
          padding-right: 7%;
        }
      }
    }
  }
}

@media screen and (max-width: 480px){

  .modelconcept-container{
    max-width: 93%;
    .modelconcept-title{
      font-size: 4vw;
    }

    .modelconcept-content {
      font-size: 3vw;
    }
    .modelconcept-list{
      font-size: 3vw;

      .logo-1,.logo-2,.logo-3,.logo-4,.logo-5{
        width: 6vw;
        padding-right: 7%;
      }

      .list-two{
        .logo-6,.logo-7,.logo-8,.logo-9{
          width: 6vw;
          padding-right: 7%;
        }
      }
    }
  }
}
