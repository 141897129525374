.div-rv-entreprise {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../../public/images/header_entreprise.jpg");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: flex-start;
  //margin: 1.9% 9.9% 21% 0%;
  //margin: 4% 0% 14.0625% 0%;
}

.container-rv-entreprise {
  width: 77%;
  display: flex;
  flex-direction: column;
  margin-top: negativePxToPourcent(5px, 1920px);
}