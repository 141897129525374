*{box-sizing: border-box;}

body{
  margin: 0;
  padding: 0;
  //background-color: $background-body;
  font-size: 16px;
  line-height: 1.6;

  font-family: Gudea;
}
h1{
  font-size: 3em;
}
h2{
  font-size: 2.5em;
  margin-bottom:30px;

}
h3{
  font-size: 2em;
}
h4{
  font-size: 1.5em;
}

.green-banner{
  padding: 0 80px 0 80px;
  height: 230px;
  //background-color: $green;
  color: white;
  position: relative;
  top:0;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  h3{
    color: white;
  }
}
.card-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}
.in-building{
  margin: 60px 0 60px 0;
}
.l-green{
  color: #618226;
}
.l-bold{
  font-weight: bold;
}

.hidden{
  display: none;
}

button{
  cursor: pointer;
  border: none;
}
