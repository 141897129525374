$width_screen: 1920px;

.categories{
  max-width: 100%;

  .categories-title {
    padding-top: pxToPourcent(107px,$width_screen);
    padding-left: pxToPourcent(190px,$width_screen);
    font-style: Droid Sans, bold;
    font-size: pxToVw(44px,$width_screen);
    font-weight: bold;
    
  }

  .categories-content {
    $width_categories_content: 1374px;
    padding-top: pxToPourcent(31px,$width_screen);
    padding-left: pxToPourcent(190px,$width_screen);
    padding-right: pxToPourcent(190px,$width_screen);
    padding-bottom: pxToPourcent(50px,$width_screen);
    font-size: pxToVw(20px,$width_screen);
    
  }

  .m-search-product{
    padding-left: pxToPourcent(190px,$width_screen);
    
    .frm-search{
      display: flex;
      justify-content: space-between;
      margin: 15px 0 15px 0;
    }

}


.m-categories {
  text-align: left;
  padding : 0px 60px 80px 60px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;

    .category-element {
      margin-top: 15px;
      position: relative;
      height: 220px;
      width: 345px;
      display: flex;
      float: left;

      .element-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
      }
      .element-title {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);

        position: absolute;
        text-align: center;
        bottom: 25px;
        left: 0;
        z-index: 100;

      }
      .fond-vert{
        top: 20%;
        width: 30%;
        height: 15%px;
        background-color: #618227 ;
        opacity: 3;
       
        
    }
  }
}
}

@media screen and (max-width: 1024px) and (max-height: 600px) {


  .categories{

    .categories-content{
      font-size: 1.5vw;
    }
    .m-search-product {
      padding-left: 0;
    }
  }

  div.search-functionality{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img, a{
      width: 30%;
      height: fit-content;
      img{
        width: 100%;
        padding-bottom: 8%;
      }
    }
  }
}



@media screen and (max-width: 1024px) and (min-height: 620px) {

  .categories{
    .categories-title{
      font-size: 4vw;
    }
    .categories-content{
      font-size: 3vw;
    }

    .m-search-product {
      padding-left: 0;
    }
  }

  div.search-functionality{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img, a{
      width: 40%;
      height: fit-content;
      img{
        width: 100%;
        padding-bottom: 8%;
      }
    }
  }

}

@media screen and (max-width: 480px) {

  .categories{
    .categories-title{
      font-size: 4vw;
    }
    .categories-content{
      font-size: 3vw;
    }
    .m-search-product {
      padding-left: 0;
    }
  }

  div.search-functionality{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img, a{
      width: 40%;
      height: fit-content;
      img{
        width: 100%;
        padding-bottom: 8%;
      }
    }
  }



}
