$width_screen: 1920px;

.repertory-description{
  position: relative;
  text-align: left;
  max-width: 100%;
  padding-left: pxToPourcent(190px,$width_screen);
  padding-right: pxToPourcent(317px,$width_screen);
  padding-top: pxToPourcent(144px,$width_screen);
  padding-bottom: 1%;
  //background-image: url("/css/img/image-partie-économie-verte.png");

  .repertory-image {
    z-index: 0;
    position: absolute;
    opacity: 0.1;
    width: 100%;
    margin-left: negativePxToPourcent(190px,$width_screen);
    margin-top: negativePxToPourcent(144px,$width_screen);
  }
  .repertory-content {
    $width_responsible_content: 1413px;

    .title {
      h1 {
        //padding-right: pxToPourcent(-317px,$width_screen);
        font-size: pxToVw(44px,$width_screen);
        font-weight: bold;
      } 
      
    }

    .title-content {
      padding-top: pxToPourcent(41px,$width_screen);
      font-size: pxToVw(20px,$width_screen);
    }
        
    .responsible-production {
      margin-bottom: pxToPourcent(220,$width_screen);
      margin-top:  pxToPourcent(66,$width_screen);
      position: relative;
      text-align: left;
      justify-content: space-around;
      background-color: white;

      h3 {
        &.l-green {
          font-size: pxToVw(44px,$width_screen);
          padding-top: pxToPourcent(150px,$width_screen);
          padding-right: pxToPourcent(75,$width_screen);
          padding-bottom: 5%;
        }
      }

      .logo-eye {
        //padding-top: pxToPourcent(110px,$width_screen);
        //height: 80%;
        padding-left: 20px;
      }

      .responsible-title{
        width: 70%;
        padding-left: pxToPourcent(100,$width_screen);
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .responsible-content-1 {
        font-size: pxToVw(20px,$width_screen);
        padding-top: pxToPourcent(89px,$width_screen);
        padding-left: pxToPourcent(100,$width_screen);
        padding-right: pxToPourcent(196,$width_screen);
      }
        
      }

      .responsible-content-2 {
        font-size: pxToVw(20px,$width_screen);
        padding-top: pxToPourcent(24.59px,$width_screen);
        padding-left: pxToPourcent(100,$width_screen);
        padding-bottom: pxToPourcent(119,$width_screen);
        padding-right: pxToPourcent(397,$width_screen);
      }
    
  }
}

.texte {
  text-decoration: black;
  opacity: 1;
}

@media screen and (max-width: 1024px) and (max-height: 600px) {

  .repertory-description{
    background-color: unset;
    background-image: url("../../../public/images/homeCompany/image-partie-économie-verte.png");
    .repertory-content {


      .title-content{
        font-size: 1.5vw;
      }

      .responsible-production{

        .responsible-content-1, .responsible-content-2{
          font-size: 1.5vw;
        }
      }
    }
  }
}



@media screen and (max-width: 1024px) and (min-height: 620px) {

  .logo-eye {
    width: 40%;
  }

  .repertory-description{
    .repertory-content {

      .title {

        h1 {
          font-size: 4vw;
        }
      }

      .title-content{
        font-size: 3vw;
      }

      .responsible-production{
        h3.l-green{
          font-size: 4vw;
        }

        .responsible-content-1, .responsible-content-2{
          font-size: 3vw;
        }
      }
    }
  }
}

@media screen and (max-width: 480px){
  .logo-eye {
    width: 40%;
  }

  .repertory-description{
    .repertory-content {

      .title {

        h1 {
          font-size: 4vw;
        }
      }

      .title-content{
        font-size: 3vw;
      }

      .responsible-production{
        h3.l-green{
          font-size: 4vw;
        }

        .responsible-content-1, .responsible-content-2{
          font-size: 3vw;
        }
      }
    }
  }
}





